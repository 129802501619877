:root {
  --primary-color: #c61260;
  --primary-color-dark: #c61260;
  --primary-color-success: #388e3c;
  --text-highlight: #cd000b;
  --bg-color: #e5e5e5;
}

* {
  font-family: "Jost", sans-serif !important;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background: #e5e5e5;
  overflow-x: hidden;
  text-transform: capitalize;
}
.rowBackground {
  background-color: #445dd726;
}

a {
  text-decoration: none !important;
  color: "#000" !important;
}

.ant-table-cell a {
  color: #000 !important;
}

.borderRight {
  border-right: none !important;
}
header {
  z-index: 10 !important;
}
main {
  background: #e5e5e5;
  min-height: calc(100vh - 128px);
}

.App {
  min-height: 100vh;
}

span,
label,
p {
  font-weight: 500;
}

.scrollBarOff::-webkit-scrollbar {
  width: 0px;
  display: none;
}

.summaryClass .noBorder {
  border-bottom: none !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #203495;
  text-shadow: 0 0 0.25px currentcolor;
  font-weight: 500;
}

.ant-layout-sider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ant-layout-sider::-webkit-scrollbar {
  width: 0px;
  display: none;
}
.pageProgress {
  height: 100vh;
  width: 100%;
  background: #e5e5e5;
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next {
  background-color: #fff !important;
}

.ant-pagination-disabled {
  background-color: #eee !important;
}

.maskRadius {
  border-radius: 50% !important;
}

.fakeimage {
  width: 100px;
  height: 100px;
  background: #c7cbd438;
  border-radius: 50%;
  cursor: pointer;
}

.flex {
  display: flex;
}

.flexRow {
  flex-direction: row;
}
.flexColumn {
  flex-direction: column;
}

.alignCenter,
.align-center {
  align-items: center;
}

.justifyCenter,
.justify-center {
  justify-content: center;
}

/* Margins */
.mt05 {
  margin-top: 0.5rem !important;
}
.mt1 {
  margin-top: 1rem;
}
.mt15 {
  margin-top: 1.5rem;
}
.mt2 {
  margin-top: 2rem;
}
.mb05 {
  margin-bottom: 0.5rem;
}
.mb1 {
  margin-bottom: 1rem;
}
.mb15 {
  margin-bottom: 1.5rem;
}
.mb2 {
  margin-bottom: 2rem;
}
.mb275 {
  margin: 0.275rem 0;
}
.mt275 {
  margin-top: 0.275rem;
}
.mt75 {
  margin-top: 0.75rem;
}
.mr275 {
  margin-right: 0.275rem;
}
.mr1 {
  margin-right: 1rem;
}
.mr05 {
  margin-right: 0.5rem;
}
.ml05 {
  margin-left: 0.5rem;
}
/* Padding */
.p05 {
  padding: 0.5rem !important;
}
.p1 {
  padding: 1rem;
}
.p15 {
  padding: 1.5rem !important;
}
.p2 {
  padding: 2rem !important;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background-color: rgba(0, 0, 0, 0.1);
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none !important;
}
.ant-card-body {
  width: 100%;
  height: 100%;
}

.ant-btn-primary {
  box-shadow: none !important;
}

.attendance .ant-descriptions-bordered .ant-descriptions-view {
  border: none;
}

.ant-table-tbody {
  background-color: white;
}
.ant-table-tbody * {
  font-size: 14px;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.logo {
  height: 36px;
  user-select: none;
  object-fit: contain;
  object-position: center;
}

.logout {
  display: flex;
  align-items: center;
}

.profileImage {
  width: 32px;
  height: 32px;
  border-radius: 12px;
}

.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.mainContent {
  min-height: calc(100vh - 148px);
}

.loginFormContainer {
  width: 400px;
}

.logout {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 0.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.lowercase {
  text-transform: lowercase !important;
}

.ant-table-wrapper {
  overflow: auto;
}

.table tr:nth-child(even) {
  background-color: #f8f8f8;
}

.customTable * {
  font-size: 13px;
}

.customTable {
  width: 100%;
  background-color: #fff;
}

.ant-table-selection-column {
  display: none !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #f5eee2 !important;
}

.customTable thead th {
  padding: 8px;
  font-weight: 500;
  background: #fafafa;
}

.customTable th p {
  margin: 8px 0px;
  border-right: 1px solid #eee;
  padding-right: 8px;
}

.ant-table-container span,
.ant-table-container p {
  white-space: nowrap;
}

.customTable input {
  text-align: center;
  border: 1px solid #aaa;
  padding: 4px 8px;
  border-radius: 2px;
}

.customTable input::-webkit-input-placeholder {
  color: #eee;
}

.customTable button {
  background-color: #002b44;
  color: #fff;
  cursor: pointer;
}

.customTable tr {
  border-bottom: 1px solid #eee;
}
.customTable td {
  align-items: center;
  text-align: center;
  padding: 16px;
}

.ant-anchor-ink::before {
  background-color: rgba(0, 0, 0, 0);
}

.ant-descriptions-item-label {
  font-weight: 500;
  color: #000 !important;
}

.ant-form-item {
  margin-bottom: 16px;
}

.selected {
  background: #9f0000;
}
.bottom-menu-selected {
  background: #f7e0e0;
}

.purchasingStats span {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.275rem;
}

.ant-btn-sm {
  font-size: 12px;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #f7f7f7;
}

.ant-tabs-nav::before {
  border-bottom: none !important;
}

.fixedRightBottom {
  position: fixed;
  right: 62px;
  bottom: 62px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #ddd;
}
.stats {
  display: flex;
  height: 140px;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.stats p {
  font-size: 16px;
  margin-bottom: 0.375rem;
}
.stats img {
  width: 50%;
  object-fit: contain;
  padding: 1rem;
  max-height: 100%;
}

.bottomBar {
  display: none;
}

.stats .count {
  margin-left: 0.5rem;
  font-size: 14px;
}

.printOnly,
.section-to-hide {
  display: none !important;
}

.statsPage .ant-tag {
  padding: 0.275rem 0.775rem;
  background-color: #ebebeb;
  border: none;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.section-to-print-raw {
  background-color: #fff;
  padding: 2rem;
  min-height: 100vh;
  width: 720px;
}

.checkpoint .ant-input-disabled,
.checkpoint .ant-select-selector,
.checkpoint .ant-input-number-disabled {
  background-color: #fff !important;
  color: #000 !important;
}

.A4 p {
  margin-bottom: 0;
}

.A4 .ant-table-content * {
  font-size: 13px !important;
}

.table .heading {
  display: none;
}

.tabMenu {
  display: flex;
}

.purchasingStats h4 {
  text-align: center;
}

.tabMenu div {
  margin-right: 1rem;
}
.tabMenu div a {
  display: flex;
  color: #000 !important;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  font-weight: 500;
}
.tabMenu .menuSelected {
  border-bottom: 2px solid #000;
}
.tabMenu div:hover {
  background-color: #eee;
}

.ant-comment-content-author-name {
  width: 100%;
}

.printOnlyHeader {
  display: none !important;
}

.responsiveOverflow {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.responsiveOverflow::-webkit-scrollbar {
  width: 0px;
  display: none;
}

.ant-tooltip-content {
  display: none;
}

@media (max-width: 768px) {
  .ant-picker-panels {
    display: flex !important;
    flex-wrap: wrap !important;
    width: min-content !important;
  }

  .responsiveOverflow {
    overflow-x: scroll;
  }

  .purchasingStats span {
    font-size: 14px;
  }
  nav {
    left: 0 !important;
  }
  main {
    padding: 0.25rem;
    padding-bottom: 64px;
  }
  .bottomBar {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 64px;
    background-color: #fff;
    z-index: 1100;
    border-top: 1px solid #eee;
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 16px;
  }

  .statusGrid {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .report {
    gap: 0.5rem;
  }
  .report div {
    width: auto !important;
    margin-right: 0 !important;
  }

  .orderItem {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 1rem;
  }
}
@media (max-width: 472px) {
  .logo {
    width: 140px;
  }
  .table .heading {
    display: block;
  }

  .table th {
    display: none;
  }

  .ant-table-container {
    border: none !important;
  }

  .table tbody tr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 8px solid #e5e5e5;
  }
  .loginFormContainer {
    width: 100%;
  }
  .loginContainer {
    background-color: white;
  }

  .loginContainer .ant-card-bordered {
    border: none;
  }
  .mainContent {
    overflow: hidden;
  }
}

.minWidth200 {
  min-width: 100px;
}

@media print {
  html {
    background-color: white;
  }
  body {
    background-color: white;
  }
  body * {
    visibility: hidden;
    -webkit-print-color-adjust: exact !important;
    min-height: auto !important;
  }

  *::-webkit-scrollbar {
    width: 0px;
    display: none;
  }

  main {
    background-color: white;
  }
  .section-to-print,
  .section-to-print * {
    visibility: visible !important;
    overflow-x: hidden;
  }
  .section-to-print {
    position: absolute !important;
    margin: auto;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    height: 100%;
    background-size: 80% !important;
    padding: 0.5rem !important;
    width: auto !important;
    height: auto !important;
  }
  .printComp {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .mainContent {
    min-height: auto !important;
  }
  .noPageBreak {
    page-break-after: always !important;
    page-break-before: always !important;
    page-break-inside: avoid !important;
  }
  .printOnly {
    display: flex !important;
    margin-top: 5rem !important;
  }
  .printOnlyHeader {
    display: flex !important;
  }
  .noPrint {
    display: none !important;
  }
  .noPrint * {
    display: none !important;
  }
  .ant-table-content * {
    font-size: 13px !important;
  }
}
